<template>
  <div>
  <el-dialog v-model="legalEntityChangeFormVisible"
             :title="`Смена юр.лица для оператора ${legalEntityChangeForm.operName}`"
             class="modal-95-70-60"
  >
    <el-form label-position="top">
      <el-form-item label="ИНН">
        <el-input v-model="legalEntityChangeForm.entityInnInput" clearable/>
      </el-form-item>
      <el-button type="success" :loading="enrichLoading" @click="loadEntitySuggestions()"
                 :disabled="!legalEntityChangeForm.entityInnInput.trim().length">Продолжить
      </el-button>
    </el-form>
    <el-divider class="blue-divider"/>
    <div
        class="inn-option"
        :class="selectedInnOptionKey === opt.value ? 'selected' : ''"
        v-for="(opt, k) in legalEntityOptions"
        :key="k"
        @click="selectInnOption(opt)"
    >
      <h4 class="no-margin">{{ opt.unrestricted_value }}</h4>
    </div>
    <el-button type="success" :loading="entityUpdating" @click="updateEntityForOperator()"
               :disabled="!legalEntityChangeForm.selectedEntity.value">Сохранить
    </el-button>
  </el-dialog>
  <el-dialog v-model="manageModalVisible"
             :title="isAddMode ? 'Новая подпись' : 'Управление заявкой'"
             width="100%"
  >
    <p v-show="!isAddMode"><span style="color: red">*</span>&nbsp;Поскольку интеграция с СМСЦ в данный момент не реализована, для
      активации имени выберите общий статус "Активно" и сохраните заявку</p>
    <el-divider class="blue-divider" v-show="!isAddMode"/>
    <el-row :gutter="20">
      <el-col :md="12" :xs="24" v-if="!isAddMode">
        <h4>Общие сведения</h4>
        <p>
          <span class="text-bold">Имя:&nbsp;</span>
          <span v-if="managingRequestIntStatusCheckLoading" style="color: red">Идёт проверка международных имён... &nbsp;&nbsp;&nbsp; | &nbsp;&nbsp;&nbsp;</span>
          {{ managingRequest.name }}&nbsp;&nbsp;&nbsp;
          <el-tooltip
              class="box-item"
              effect="dark"
              :content="`Имя зарегистрировано как международное на операторах: ${intOperatorsNames(managingRequest)}`"
              placement="bottom"
              v-if="(managingRequest.intNamesMatch || []).length"
          >
            <span style="color: red; cursor: pointer">Международное</span>
          </el-tooltip>
        </p>
        <p v-show="!isAddMode"><span class="text-bold">Тип:&nbsp;</span> {{ managingRequest.forCalls ? 'Звонки' : 'СМС' }}</p>
        <p v-show="!isAddMode"><span class="text-bold">Дата:&nbsp;</span> {{ _formatDateTime(managingRequest.date) }}</p>
        <p><span class="text-bold">Контрагент:&nbsp;</span> {{ managingRequest.user_id }}</p>
        <p><span class="text-bold">Описание:&nbsp;</span> {{ managingRequest.description }}</p>
        <el-divider class="blue-divider hidden-md-and-up"/>
      </el-col>
      <el-col :md="12" :xs="24" v-if="isAddMode">
        <h4>Общие сведения</h4>
        <el-form label-position="top">
          <el-form-item label="Подпись">
            <el-input v-model="managingRequest.name" />
          </el-form-item>
          <el-form-item label="Контрагент">
            <client-select v-model="managingRequest.user_id"  :hide-inactive="true" :disabled="managingRequest.disableUserChoice"/>
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :md="12" :xs="24">
        <h4>Общая настройка</h4>
        <el-form label-position="top">
          <el-form-item label="Статус" v-if="!isAddMode">
            <el-select class="full-width" v-model="managingRequest.globalStatus" v-if="!managingRequest.removed">
              <el-option value="" disabled label="Не указан"/>
              <el-option value="active" label="Активно"/>
              <el-option value="inactive" label="Отклонено"/>
              <el-option value="removed" label="Удалено"/>
            </el-select>
            <h3 style="color: red; font-weight: bold; word-break: break-word" v-if="managingRequest.removed">
              УДАЛЕНА</h3>
          </el-form-item>
          <el-form-item label="Комментарий">
            <el-input type="textarea" v-model="managingRequest.reason"/>
          </el-form-item>
          <el-form-item label="Уведомить клиента о добавлении подписи" v-show="isAddMode">
            <el-switch v-model="notifyClient" />
          </el-form-item>
          <h3 style="color: var(--el-color-danger); font-weight: bold; word-break: break-word"
              v-if="managingRequest.moveToFreeRateOnAllOperatorsRequested">Запрошен отзыв со всех операторов</h3>
        </el-form>
      </el-col>
    </el-row>
    <el-divider class="blue-divider"/>
    <el-row :gutter="20">
      <el-col :span="24">
        <h4>Настройка по операторам</h4>

        <h4 style="color: var(--el-color-danger)" v-if="!managingNameHasOperatorSettings">Не указана</h4>
        <div v-if="managingNameHasOperatorSettings">
          <el-table :data="managingRequestOperatorSettingsTableData">
            <el-table-column label="Оператор" prop="operatorName" width="80"/>
            <el-table-column label="Тип" prop="type" width="170">
              <template #default="scope">
                <el-select v-model="managingRequest.operatorSettings[scope.row.operKey].type" class="full-width">
                  <el-option value="individual" label="Платное"/>
                  <el-option value="common" label="Бесплатное"/>
                </el-select>
                <p style="color: var(--el-color-danger)"
                   v-show="managingRequest.operatorSettings[scope.row.operKey].moveToFreeRateRequested">Запрошен
                  перенос на бесплатный тариф</p>
              </template>
            </el-table-column>
            <el-table-column label="Активация" prop="activation" width="140">
              <template #default="scope">
                <span v-if="!isAddMode">{{scope.row.activation}}</span>
                <el-select v-if="isAddMode" v-model="managingRequest.operatorSettings[scope.row.operKey].activation" class="full-width">
                  <el-option value="immediate" label="Сразу"/>
                  <el-option value="next_month" label="С 1 числа"/>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column label="Файлы" width="90">
              <template #default="scope">
                <a :href="`${axios.defaults.baseURL}/admin/support/downloadSenderNameAttachment?nameId=${managingRequest._id}&operator=${scope.row.operKey}&token=${$store.getters.bareToken}`"
                   target="_blank" v-show="scope.row.files && scope.row.files.length">Скачать</a>
              </template>
            </el-table-column>
            <el-table-column label="Текущий статус" prop="currentStatusText" width="300">
              <template #default="scope">
                <el-select v-model="managingRequest.operatorSettings[scope.row.operKey].currentStatus"
                           class="full-width">
                  <el-option value="" label="Не рассмотрено"/>
                  <el-option value="sent" label="Отправлено на регистрацию"/>
                  <el-option value="rejected" label="Отклонено"/>
                  <el-option value="approved" label="Подтверждено"/>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column label="Комментарий" prop="comment" min-width="200">
              <template #default="scope">
                <el-input type="textarea" :rows="3"
                          v-model="managingRequest.operatorSettings[scope.row.operKey].comment"/>
              </template>
            </el-table-column>
            <el-table-column label="Юр.лицо" prop="comment" min-width="120" v-if="!isAddMode">
              <template #default="scope">
                  <span
                      v-html="(managingRequest.operatorSettings[scope.row.operKey].legalEntity || '').split('|').reverse().join('<br/>')"></span><br/>
                <el-button text class="text-button table-text-button blue-button"
                           @click="requestLegalEntityChange(scope.row)">Сменить
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-col>
    </el-row>
    <template #footer>
      <el-button type="success" @click="saveRequest" :loading="saving" :disabled="!(this.managingRequest.name || '').trim()">Сохранить</el-button>
    </template>
  </el-dialog>
  </div>
</template>

<script>

import TableSpinner from "../../../shared/components/layout/table-spinner.component.vue";
import RemoveButton from "../../../shared/components/layout/removeButton.component.vue";
import ClientSelect from "@/components/layout/client-select.component.vue";

const OPERATOR_NAMES = {
  mts: "МТС",
  megafon: "Мегафон",
  beeline: "Билайн",
  tele2: "Теле2",
}

export default {
  name: "SenderNamesManageModal",
  components: {ClientSelect, RemoveButton, TableSpinner},
  methods: {
    _formatDateTime(v) {
      return this.formatDateTime(v)
    },
    openAdd(systemId=""){
      this.isAddMode = true
      this.managingRequest = this.getDefaultManagingNameState()
      this.managingRequest.globalStatus = "active"
      this.managingRequest.operatorSettings = Object.keys(OPERATOR_NAMES).reduce((acc, k) => {
        acc[k] = {
          "activation" : "immediate",
          "type": "common",
          "currentStatus": "approved",
          "comment": ""
        }
        return acc
      }, {})
      if(systemId) {
        this.managingRequest.user_id = systemId
        this.managingRequest.disableUserChoice = true
      }
      this.notifyClient = false
      this.manageModalVisible = true
    },
    openEdit(request) {
      this.isAddMode = false
      this.managingRequest = request
      this.managingRequest.globalStatus = ""
      if (request.removed) this.managingRequest.globalStatus = "removed"
      if (String(request.active) === "0") this.managingRequest.globalStatus = "inactive"
      if (String(request.active) === "1") this.managingRequest.globalStatus = "active"
      this.manageModalVisible = true
      this.managingRequestIntStatusCheckLoading = false
      this.axios.get("/admin/support/internationalStatusOfRequest", {params: {name: request.name}})
          .then(resp => {
            this.managingRequestIntStatusCheckLoading = false
            this.managingRequest.intNamesMatch = resp.data

          })
    },
    saveRequest() {
      if(!(this.managingRequest.name || "").trim()) return
      this.saving = true
      let url = this.isAddMode ? "/admin/support/createSenderName" : "/admin/support/updateSenderNameRequest"
      this.axios.post(url, {
        requestId: this.managingRequest._id,
        userId: this.managingRequest.user_id,
        name: this.managingRequest.name,
        operatorSettings: this.managingRequest.operatorSettings,
        globalStatus: this.managingRequest.globalStatus,
        reason: this.managingRequest.reason,
        notifyClient: this.notifyClient
      })
          .then(resp => {
            this.saving = false
            this.$gNotify("Подпись создана", "success")
            this.manageModalVisible = false
            this.$emit("refresh")
          })
    },

    requestLegalEntityChange(operRow) {
      this.legalEntityChangeForm.operKey = operRow.operKey
      this.legalEntityChangeForm.operName = operRow.operatorName
      this.legalEntityChangeFormVisible = true
    },
    loadEntitySuggestions() {
      this.enrichLoading = true
      this.legalEntityChangeForm.selectedEntity = {}
      this.axios.get("/user/innEnrichment", {params: {inn: this.legalEntityChangeForm.entityInnInput.trim()}})
          .then(resp => {
            this.enrichLoading = false
            this.legalEntityOptions = resp.data.suggestions
          })
          .catch(e => {
            console.error(e)
            this.$gNotify("Неверный ИНН или ошибка сервера", "error")
            this.enrichLoading = false
          })
    },
    selectInnOption(opt) {
      if (this.selectedInnOptionKey === opt.value) {
        this.selectedInnOptionKey = ""
        this.legalEntityChangeForm.selectedEntity = {}
      } else {
        if (opt.data && opt.data.data && opt.data.data.state && ["LIQUIDATED", "LIQUIDATING", "BANKRUPT"].includes(opt.data.data.state.status))
          return this.$gNotify("Введите ИНН действующей компании!", "error")
        this.selectedInnOptionKey = opt.value
        this.legalEntityChangeForm.selectedEntity = opt
      }
    },
    updateEntityForOperator() {
      this.entityUpdating = true
      this.axios.post("/admin/support/updateSenderNameLegalEntityForOperator", {
        requestId: this.managingRequest._id,
        operKey: this.legalEntityChangeForm.operKey,
        inn: this.legalEntityChangeForm.selectedEntity.data.inn,
        company_name: this.legalEntityChangeForm.selectedEntity.data.name.short_with_opf,
      })
          .then(resp => {
            this.entityUpdating = false
            this.$gNotify("Юр.лицо обновлено", "success")
            this.legalEntityChangeFormVisible = false
            this.manageModalVisible = false
            this.$emit("refresh")
          })
    },
    intOperatorsNames(request) {
      return request.intNamesMatch.map(n => {
        let og = this.$store.getters.operatorsGroups.find(g => g._id === n.operatorId)
        if (og) return og.title
        let op = this.$store.getters.operators.find(g => g._id === n.operatorId)
        if (op) return op.OrgName
        return ""
      }).join(' | ')
    },
    getDefaultManagingNameState(){
      return {
        _id: "",
        name: "",
        forCalls: "",
        date: "",
        user_id: "",
        globalStatus: "",
        reason: "",
        operatorSettings: null,
        intNamesMatch: [],
        disableUserChoice: false
      }
    }
  },
  computed: {
    managingNameHasOperatorSettings() {
      return this.managingRequest.operatorSettings && Object.keys(this.managingRequest.operatorSettings).length
    },
    managingRequestOperatorSettingsTableData() {
      let operSettings = []
      if (this.managingNameHasOperatorSettings)
        for (let key of ["mts", "megafon", "beeline", "tele2"]) {
          operSettings.push({
            operatorName: OPERATOR_NAMES[key],
            type: this.managingRequest.operatorSettings[key].type === "common" ? "Бесплатное" : "Платное",
            activation: this.managingRequest.operatorSettings[key].activation === "next_month" ? "С первого числа" : "Сразу",
            files: this.managingRequest.operatorSettings[key].files,
            operKey: key
          })
        }
      return operSettings
    }
  },
  data() {
    return {

      manageModalVisible: false,
      managingRequestIntStatusCheckLoading: false,
      managingRequest: this.getDefaultManagingNameState(),
      saving: false,
      legalEntityChangeFormVisible: false,
      enrichLoading: false,
      legalEntityOptions: [],
      selectedInnOptionKey: "",
      legalEntityChangeForm: {
        operName: "",
        operKey: "",
        entityInnInput: "",
        selectedEntity: {}
      },
      notifyClient: false,
      entityUpdating: false,

      isAddMode: false
    }
  },
}

</script>